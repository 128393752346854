import type { Dayjs, OpUnitType } from 'dayjs';

import dayjs from 'dayjs';
import moment from 'moment';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';

// ----------------------------------------------------------------------

/**
 * @Docs
 * https://day.js.org/docs/en/display/format
 */

/**
 * Default timezones
 * https://day.js.org/docs/en/timezone/set-default-timezone#docsNav
 *
 */

/**
 * UTC
 * https://day.js.org/docs/en/plugin/utc
 * @install
 * import utc from 'dayjs/plugin/utc';
 * dayjs.extend(utc);
 * @usage
 * dayjs().utc().format()
 *
 */
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(calendar);

// ----------------------------------------------------------------------

export type DatePickerFormat = Dayjs | Date | string | number | null | undefined;

export const formatPatterns = {
  dateTime: 'MMM DD YYYY h:mm a', // 17 Apr 2022 12:00 am
  date: 'MMM DD YYYY', // 17 Apr 2022
  time: 'hh:mm a', // 12:00 am
  split: {
    dateTime: 'MM/DD/YYYY h:mm a', // 17/04/2022 12:00 am
    date: 'MM/DD/YYYY', // 17/04/2022
    time: 'hh:mm a',
  },
  paramCase: {
    dateTime: 'MM-DD-YYYY h:mm a', // 17-04-2022 12:00 am
    date: 'MM-DD-YYYY', // 17-04-2022
  },
};

const isValidDate = (date: DatePickerFormat) =>
  date !== null && date !== undefined && dayjs(date).isValid();

// ----------------------------------------------------------------------

export function today(template?: string): string {
  return dayjs(new Date()).startOf('day').format(template);
}

// ----------------------------------------------------------------------

/**
 * @output 17 Apr 2022 12:00 am
 */
export function fDateTime(date: DatePickerFormat, template?: string): string {
  if (!isValidDate(date)) {
    return 'Invalid date';
  }

  return dayjs(date).format(template ?? formatPatterns.dateTime);
}

// ----------------------------------------------------------------------

/**
 * @output 17 Apr 2022
 */
export function fDate(date: DatePickerFormat, template?: string): string {
  if (!isValidDate(date)) {
    return 'Invalid date';
  }

  return dayjs(date).format(template ?? formatPatterns.date);
}

// ----------------------------------------------------------------------

/**
 * @output 12:00 am
 */
export function fTime(date: DatePickerFormat, template?: string): string {
  if (!isValidDate(date)) {
    return 'Invalid date';
  }

  return dayjs(date).format(template ?? formatPatterns.time);
}

// ----------------------------------------------------------------------

/**
 * @output 1713250100
 */
export function fTimestamp(date: DatePickerFormat): number | 'Invalid date' {
  if (!isValidDate(date)) {
    return 'Invalid date';
  }

  return dayjs(date).valueOf();
}

// ----------------------------------------------------------------------

/**
 * @output a few seconds, 2 years
 */
export function fToNow(date: DatePickerFormat): string {
  if (!isValidDate(date)) {
    return 'Invalid date';
  }

  return dayjs(date).toNow(true);
}

// ----------------------------------------------------------------------

/**
 * @output boolean
 */
export function fIsBetween(
  inputDate: DatePickerFormat,
  startDate: DatePickerFormat,
  endDate: DatePickerFormat
): boolean {
  if (!isValidDate(inputDate) || !isValidDate(startDate) || !isValidDate(endDate)) {
    return false;
  }

  const formattedInputDate = fTimestamp(inputDate);
  const formattedStartDate = fTimestamp(startDate);
  const formattedEndDate = fTimestamp(endDate);

  if (
    formattedInputDate === 'Invalid date' ||
    formattedStartDate === 'Invalid date' ||
    formattedEndDate === 'Invalid date'
  ) {
    return false;
  }

  return formattedInputDate >= formattedStartDate && formattedInputDate <= formattedEndDate;
}

// ----------------------------------------------------------------------

/**
 * @output boolean
 */
export function fIsAfter(startDate: DatePickerFormat, endDate: DatePickerFormat): boolean {
  if (!isValidDate(startDate) || !isValidDate(endDate)) {
    return false;
  }

  return dayjs(startDate).isAfter(endDate);
}

// ----------------------------------------------------------------------

/**
 * @output boolean
 */
export function fIsSame(
  startDate: DatePickerFormat,
  endDate: DatePickerFormat,
  unitToCompare?: OpUnitType
): boolean {
  if (!isValidDate(startDate) || !isValidDate(endDate)) {
    return false;
  }

  return dayjs(startDate).isSame(endDate, unitToCompare ?? 'year');
}

/**
 * @output
 * Same day: 26 Apr 2024
 * Same month: 25 - 26 Apr 2024
 * Same month: 25 - 26 Apr 2024
 * Same year: 25 Apr - 26 May 2024
 */
export function fDateRangeShortLabel(
  startDate: DatePickerFormat,
  endDate: DatePickerFormat,
  initial?: boolean
): string {
  if (!isValidDate(startDate) || !isValidDate(endDate) || fIsAfter(startDate, endDate)) {
    return 'Invalid date';
  }

  let label = `${fDate(startDate)} - ${fDate(endDate)}`;

  if (initial) {
    return label;
  }

  const isSameYear = fIsSame(startDate, endDate, 'year');
  const isSameMonth = fIsSame(startDate, endDate, 'month');
  const isSameDay = fIsSame(startDate, endDate, 'day');

  if (isSameYear && !isSameMonth) {
    label = `${fDate(startDate, 'DD MMM')} - ${fDate(endDate)}`;
  } else if (isSameYear && isSameMonth && !isSameDay) {
    label = `${fDate(startDate, 'DD')} - ${fDate(endDate)}`;
  } else if (isSameYear && isSameMonth && isSameDay) {
    label = `${fDate(endDate)}`;
  }

  return label;
}

// ----------------------------------------------------------------------

/**
 * @output 2024-05-28T05:55:31+00:00
 */
export type DurationProps = {
  years?: number;
  months?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
};

export function fAdd({
  years = 0,
  months = 0,
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0,
}: DurationProps) {
  const result = dayjs()
    .add(
      dayjs.duration({
        years,
        months,
        days,
        hours,
        minutes,
        seconds,
        milliseconds,
      })
    )
    .format();

  return result;
}

/**
 * @output 2024-05-28T05:55:31+00:00
 */
export function fSub({
  years = 0,
  months = 0,
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0,
}: DurationProps) {
  const result = dayjs()
    .subtract(
      dayjs.duration({
        years,
        months,
        days,
        hours,
        minutes,
        seconds,
        milliseconds,
      })
    )
    .format();

  return result;
}

//////////////////////////////////

export type DateTimeStyle = 'full' | 'long' | 'medium' | 'short' | undefined;
export type DateType = Date | string | number | null | undefined;

export const defaultDateStyle: DateTimeStyle = 'short';
export const defaultTimeStyle: DateTimeStyle = 'short';

/**
 * deserialize a date object from a string
 *
 * @param s string
 * @returns the date object
 */

export function stringToDate(s: string) {
  return s ? dayjs(s).toDate() : null;
}

/**
 * serialize a date as string
 * @param date the date
 * @returns the date as string
 */
export function dateToString(date: Date | string) {
  if (!date) {
    return null;
  }
  // if (typeof date === 'string' || date instanceof String) {
  //   return date;
  // }
  return dayjs(date).format('YYYY-MM-DD');
}

/**
 * serialize a date as string with time information
 * @param date the date
 * @returns the datetime as string
 */
export function dateTimeToString(date?: Date | string | null): string | null {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    return date;
  }
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.toISOString();
}

export function secondsToTimeString(seconds: number): string {
  return moment.utc(seconds * 1000).format('HH:mm');
}
/**
 * format a date object or string to be displayed on the UI
 * @param d date
 * @returns the formatted date
 */
export const formatDate = (d: any) =>
  // d ? new Date(d).toLocaleDateString([], { dateStyle: 'short' }) : '-';
  d ? dayjs(d).format('MM/DD/YYYY') : '-';

/**
 * format a date object or string with time information to be displayed on the UI
 * @param d date
 * @returns the formatted date with time information
 */

export const formatDashboardDateTime = (d: DateType) => {
  if (d) {
    if (dayjs(d).isSame(dayjs(), 'day')) {
      return formatTime(d);
    } else return formatCalendarDateTime(d);
  } else return '-';
};

export const formatCalendarDateTime = (d: DateType) =>
  d
    ? dayjs(d).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: '[Next] ddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] ddd',
        sameElse: 'MM/DD/YYYY',
      })
    : '-';

export const formatDateTime = (
  d: DateType,
  dateStyle = defaultDateStyle,
  timeStyle = defaultTimeStyle // (d ? new Date(d).toLocaleString([], { dateStyle, timeStyle }) : '-');
) => (d ? dayjs(d).format('MM/DD/YYYY hh:mm A') : '-');

export const formatTime = (d: DateType, timeStyle = defaultTimeStyle) =>
  // d ? new Date(d).toLocaleTimeString([], { timeStyle }) : '-';
  d ? dayjs(d).format('hh:mm A') : '-';

export const formatHour = (d: DateType, timeStyle = defaultTimeStyle) =>
  // d ? new Date(d).toLocaleTimeString([], { timeStyle }) : '-';
  d ? dayjs(d).format('hh A') : '-';

export const isDate = (d: any) => d && (d instanceof Date || !Number.isNaN(new Date(d).getTime()));

export function combineDateAndTime(date: Date, time: Date) {
  const dateTime = new Date(date);
  dateTime.setHours(time.getHours());
  dateTime.setMinutes(time.getMinutes());
  dateTime.setSeconds(0);
  dateTime.setMilliseconds(0);
  return dateTime;
}

export function formatDuration(seconds: number) {
  const hrs = dayjs.duration(seconds, 'second').hours();
  let hrs_label = 'hrs';
  if (hrs < 2) hrs_label = 'hr';
  const mins = dayjs.duration(seconds, 'second').minutes();
  let mins_label = 'mins';
  if (mins < 2) mins_label = 'min';
  return `${hrs} ${hrs_label} ${mins} ${mins_label}`.trim();
}
