import type { NavSectionProps } from 'src/components/nav-section';

import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { CONFIG } from 'src/global-config';
import { useListReports } from 'src/actions';

import { Iconify } from 'src/components/iconify';
import { SvgColor } from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`${CONFIG.assetsDir}/assets/icons/navbar/${name}.svg`} />
);

const iconify = (name: string) => <Iconify icon={name} />;

const ICONS = {
  dashboard: icon('ic-dashboard'),

  // overview
  openShipments: iconify('ph:shipping-container'),
  billedShipments: iconify('ph:shipping-container-fill'),
  dispatchedTrips: iconify('mdi:truck'),
  undispatchedTrips: iconify('mdi:truck-outline'),
  invoices: iconify('hugeicons:money-receive-square'),
  payments: iconify('hugeicons:money-send-square'),
  reports: iconify('mdi:file-report'),

  // management
  users: iconify('mdi:user'),
  groups: iconify('mdi:account-group'),
  customers: iconify('mdi:company'),
  locations: iconify('mdi:location'),
  trucks: iconify('mdi:truck'),
  privateChassis: iconify('fa-solid:trailer'),
  trailerType: iconify('mdi:category'),
  terminals: iconify('zondicons:network'),
  steamships: iconify('mdi:boat'),
  templates: iconify('mdi:message-alert'),

  planner: iconify('mdi:automatic'),
  chat: iconify('mingcute:chat-2-line'),
  import: iconify('mdi:import'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { reports, mutate: reloadReports } = useListReports();

  const navData: NavSectionProps['data'] = useMemo(
    () => [
      /**
       * Overview
       */
      {
        subheader: 'OPERATIONS',
        items: [
          { title: 'CS Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
          {
            title: 'Open Loads',
            path: paths.dashboard.shipments.list,
            icon: ICONS.openShipments,
          },
          {
            title: 'Billed Loads',
            path: paths.dashboard.billedShipments.list,
            icon: ICONS.billedShipments,
          },
          {
            title: 'Dispatched Trips',
            path: paths.dashboard.dispatchedTrips.list,
            icon: ICONS.dispatchedTrips,
          },
          {
            title: 'Undispatched Trips',
            path: paths.dashboard.undispatchedTrips.list,
            icon: ICONS.undispatchedTrips,
          },
          {
            title: 'Chat',
            path: paths.dashboard.chat,
            icon: ICONS.chat,
          },
          {
            title: 'Auto Planner',
            path: paths.dashboard.planner,
            icon: ICONS.planner,
          },
          {
            title: 'Operations Reports',
            path: paths.dashboard.reportsView.root,
            icon: ICONS.reports,
            children: reports.map((r) => ({
              title: r.name,
              path: paths.dashboard.reportsView.view(r.id),
            })),
          },
        ],
      },
      {
        subheader: 'ACCOUNTING',
        items: [
          { title: 'Customer Invoices', path: paths.dashboard.invoices.list, icon: ICONS.invoices },
          {
            title: 'Driver Payments',
            path: paths.dashboard.driverPayments.list,
            icon: ICONS.payments,
          },
          {
            title: 'Accounting Reports',
            path: paths.dashboard.reportsView.root,
            icon: ICONS.reports,
            children: reports.map((r) => ({
              title: r.name,
              path: paths.dashboard.reportsView.view(r.id),
            })),
          },
        ],
      },

      /**
       * Management
       */
      {
        subheader: 'Management',
        items: [
          { title: 'Users', path: paths.dashboard.users.list, icon: ICONS.users },
          { title: 'Groups', path: paths.dashboard.groups.list, icon: ICONS.groups },
          { title: 'Customers', path: paths.dashboard.customers.list, icon: ICONS.customers },
          { title: 'Locations', path: paths.dashboard.locations.list, icon: ICONS.locations },
          { title: 'Terminals', path: paths.dashboard.terminals.list, icon: ICONS.terminals },
          { title: 'Trucks', path: paths.dashboard.trucks.list, icon: ICONS.trucks },
          {
            title: 'Private Chassis',
            path: paths.dashboard.privateChassis.list,
            icon: ICONS.privateChassis,
          },
          {
            title: 'Trailer Type',
            path: paths.dashboard.trailerType.list,
            icon: ICONS.trailerType,
          },
          { title: 'Steamships', path: paths.dashboard.steamships.list, icon: ICONS.steamships },
          { title: 'Templates', path: paths.dashboard.templates.list, icon: ICONS.templates },
          { title: 'Reports', path: paths.dashboard.reports.list, icon: ICONS.reports },
        ],
      },
      // Tools
      {
        subheader: 'Tools',
        items: [
          {
            title: 'Import Data',
            path: paths.dashboard.imports.root,
            icon: ICONS.import,
          },
        ],
      },
    ],
    [reports]
  );
  return { navData, reloadReports };
}
