// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {

  // AUTH
  auth: {
    jwt: {
      signIn: `${ROOTS.AUTH}/jwt/sign-in`,
      signUp: `${ROOTS.AUTH}/jwt/sign-up`,
    },
  },

  // DASHBOARD
  dashboard: {

    // overview
    root: ROOTS.DASHBOARD,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
    },
    shipments: {
      list: `${ROOTS.DASHBOARD}/shipments`,
      new: `${ROOTS.DASHBOARD}/shipments/new`,
      edit: (id: string | number) => `${ROOTS.DASHBOARD}/shipments/${id}`,
    },
    billedShipments: {
      list: `${ROOTS.DASHBOARD}/billed-shipments`,
    },
    dispatchedTrips: {
      list: `${ROOTS.DASHBOARD}/dispatched-trips`,
    },
    undispatchedTrips: {
      list: `${ROOTS.DASHBOARD}/undispatched-trips`,
    },
    invoices: {
      list: `${ROOTS.DASHBOARD}/invoices`,
      new: `${ROOTS.DASHBOARD}/invoices/new`,
      edit: (id: string | number) => `${ROOTS.DASHBOARD}/invoices/${id}`,
    },
    driverPayments: {
      list: `${ROOTS.DASHBOARD}/driver-payments`,
      edit: (id: string | number) => `${ROOTS.DASHBOARD}/driver-payments/${id}`,
    },
    reportsView: {
      root: `${ROOTS.DASHBOARD}/reports-view`,
      view: (id: string | number) => `${ROOTS.DASHBOARD}/reports-view/${id}`,
    },


    // management
    users: {
      list: `${ROOTS.DASHBOARD}/users`,
      new: `${ROOTS.DASHBOARD}/users/new`,
      edit: (id: string | number) => `${ROOTS.DASHBOARD}/users/${id}`,
    },
    groups: {
      list: `${ROOTS.DASHBOARD}/groups`,
      new: `${ROOTS.DASHBOARD}/groups/new`,
      edit: (id: string | number) => `${ROOTS.DASHBOARD}/groups/${id}`,
    },
    customers: {
      list: `${ROOTS.DASHBOARD}/customers`,
      new: `${ROOTS.DASHBOARD}/customers/new`,
      edit: (id: string | number) => `${ROOTS.DASHBOARD}/customers/${id}`,
    },
    locations: {
      list: `${ROOTS.DASHBOARD}/locations`,
      new: `${ROOTS.DASHBOARD}/locations/new`,
      edit: (id: string | number) => `${ROOTS.DASHBOARD}/locations/${id}`,
    },
    trucks: {
      list: `${ROOTS.DASHBOARD}/trucks`,
      new: `${ROOTS.DASHBOARD}/trucks/new`,
      edit: (id: number | string) => `${ROOTS.DASHBOARD}/trucks/${id}`,
    },
    privateChassis: {
      list: `${ROOTS.DASHBOARD}/private-chassis`,
      new: `${ROOTS.DASHBOARD}/private-chassis/new`,
      edit: (id: number | string) => `${ROOTS.DASHBOARD}/private-chassis/${id}`,
    },
    trailerType: {
      list: `${ROOTS.DASHBOARD}/trailer-type`,
      new: `${ROOTS.DASHBOARD}/trailer-type/new`,
      edit: (id: number | string) => `${ROOTS.DASHBOARD}/trailer-type/${id}`,
    },
    steamships: {
      list: `${ROOTS.DASHBOARD}/steamships`,
      new: `${ROOTS.DASHBOARD}/steamships/new`,
      edit: (id: number | string) => `${ROOTS.DASHBOARD}/steamships/${id}`,
    },
    reports: {
      list: `${ROOTS.DASHBOARD}/reports`,
      new: `${ROOTS.DASHBOARD}/reports/new`,
      edit: (id: string | number) => `${ROOTS.DASHBOARD}/reports/${id}`,
    },
    templates: {
      list: `${ROOTS.DASHBOARD}/templates`,
      new: `${ROOTS.DASHBOARD}/templates/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/templates/${id}`,
    },
    terminals: {
      list: `${ROOTS.DASHBOARD}/terminals`,
      new: `${ROOTS.DASHBOARD}/terminals/new`,
      edit: (id: number | string) => `${ROOTS.DASHBOARD}/terminals/${id}`,
    },

    chat: `${ROOTS.DASHBOARD}/chat`,
    planner: `${ROOTS.DASHBOARD}/planner`,
    imports: {
      root: `${ROOTS.DASHBOARD}/imports`,
    },    
  },
};
