import type { Customer } from 'src/types';

import useSWR from 'swr';
import { useMemo } from 'react';

import axios, { fetcher, endpoints } from 'src/lib/axios';

const URL = endpoints.customers;

export function useListCustomers(filter?: any, sort?: any, pagination?: any, relations?: any) {
  const request: any = [
    URL,
    {
      params: {
        filter,
        sort,
        pagination,
        relations,
      },
    },
  ];

  const { data, mutate, isLoading, error, isValidating } = useSWR<Customer[]>(request, fetcher);

  const memoizedValue = useMemo(
    () => ({
      customers: (data || []),
      mutate,
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.length,
    }),
    [data, mutate, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetCustomer(id: any, relations?: any) {
  const request = Boolean(id) && [`${URL}/${id}`, { params: { relations } }];
  const { data, isLoading, error, isValidating } = useSWR(request, fetcher);

  const memoizedValue = useMemo(
    () => ({
      customer: data as Customer,
      customerLoading: isLoading,
      customerError: error,
      customerValidating: isValidating,
      customerEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function createCustomer(customer: Partial<Customer>) {
  return axios.post(URL, customer);
}

export function updateCustomer(customer: Partial<Customer>) {
  return axios.put(`${URL}/${customer.id}`, customer);
}

export async function saveCustomer(customer: Partial<Customer>) {
  return customer.id ? updateCustomer(customer) : createCustomer(customer);
}

export function deleteCustomer(id: number) {
  return axios.delete(`${URL}/${id}`);
}

export function importCustomers(fileData?: any) {
  const formData = new FormData();
  formData.append('file', fileData);

  return axios.post(`${URL}/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}