import type { Shipment, ShipmentCopyDTO, TraceRailEtaResponse } from 'src/types';

import { useMemo } from 'react';
import useSWR, { mutate as mutateAll } from 'swr';

import axios, { endpoints, getFetcher, postFetcher } from 'src/lib/axios';

import { isFilterEmpty } from './util';

const URL = endpoints.shipments;

export function useListShipments(filter: any, sort?: any, limit?: any, relations?: any) {
  const request: any = (!isFilterEmpty(filter) || limit) && [
    URL,
    {
      params: {
        filter,
        sort,
        limit,
        relations,
      },
    },
  ];
  const { data, isLoading, error, isValidating, mutate } = useSWR(request, getFetcher);

  const memoizedValue = useMemo(
    () => ({
      shipments: (data || []) as Shipment[],
      mutate,
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.length,
    }),
    [data, mutate, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useSearchShipments(filter?: any, sort?: any, pagination?: any, relations?: any) {
  const request: any = [
    `${URL}/search`,
    {
      filter,
      sort,
      pagination,
      relations,
    },
  ];
  const {
    data = { results: [], count: 0 },
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(request, postFetcher);

  const memoizedValue = useMemo(
    () => ({
      results: data.results as Shipment[],
      count: data.count as number,
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data.results.length,
      mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

/**
 * by specifying the query url we can force reloading all instances
 * where find by id was used, thus triggering refresh of all components
 * that are using the same id. this requres the data to have been loaded
 * with swr using { keepPreviousData: true } to avoid flickering
 * @param id 
 */
export function forceReloadShipment(id: any) {
  mutateAll(
    (key: any) => {
      if (key instanceof Array) {
        const url = key[0];
        return url.startsWith(`${URL}/${id}`);
      }
      if (typeof key == 'string') {
        return key.startsWith(`${URL}/${id}`);
      }
      return false;
    },
    undefined,
    {  }
  )  
}

export function useGetShipment(id: any, relations?: any) {
  const request = Boolean(id) && [`${URL}/${id}`, { params: { relations } }];
  const { data, isLoading, error, isValidating, mutate } = useSWR(request, getFetcher, { keepPreviousData: true });

  const memoizedValue = useMemo(
    () => ({
      shipment: data as Shipment,
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && data,
      mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function fetchShipment(id: number, relations = {}) {
  if (!id) {
    throw new Error('Invalid shipment ID');
  }

  try {
    const response = await axios.get(`${URL}/${id}`, {
      headers: {
        Accept: 'application/json',
      },
      params: {
        relations,
      },
    });
    const { data } = response;
    return data as Shipment;
  } catch (error) {
    console.error('Failed to fetch shipment', error);
    return null;
  }
}

export async function traceShipment(id: number | number[]) {
  if (!id || (Array.isArray(id) && id.length === 0)) {
    throw new Error('Invalid shipment ID(s)');
  }

  const idParam = Array.isArray(id) ? id.join(',') : id;

  try {
    const response = await axios.get(`${URL}/trace/${idParam}`, {
      headers: {
        Accept: 'application/json',
      },
    });
    const { data } = response;
    return data as TraceRailEtaResponse;
  } catch (error) {
    console.error('Failed to trace shipment', error);
    return null;
  }
}

export function createShipment(shipment: Shipment) {
  return axios.post(URL, shipment);
}

export function updateShipment(shipment: Partial<Shipment>) {
  return axios.put(`${URL}/${shipment.id}`, shipment);
}

export function saveShipment(shipment: Shipment) {
  return shipment.id ? updateShipment(shipment) : createShipment(shipment);
}

export function copyShipment(dtos: ShipmentCopyDTO[]) {
  return axios.post(`${URL}/copy`, dtos);
}

export function importShipments(fileData?: any) {
  const formData = new FormData();
  formData.append('file', fileData);

  return axios.post(`${URL}/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
