import type { RouteObject } from 'react-router';

import { Outlet } from 'react-router';
import { lazy, Suspense } from 'react';

import { CONFIG } from 'src/global-config';
import { DashboardLayout } from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

import { AuthGuard } from 'src/auth/guard';

import { usePathname } from '../hooks';

// ----------------------------------------------------------------------

//// overview
const CSDashboardPage = lazy(() => import('src/pages/dashboard/cs-dashboard'));

// Shipments
const ShipmentsListPage = lazy(() => import('src/pages/dashboard/shipments/list'));
const ShipmentsCreatePage = lazy(() => import('src/pages/dashboard/shipments/new'));
const ShipmentsEditPage = lazy(() => import('src/pages/dashboard/shipments/edit'));
const BilledShipmentListPage = lazy(() => import('src/pages/dashboard/billed-shipment/list'));
const UndispatchedTripsListPage = lazy(() => import('src/pages/dashboard/undispatched-trips/list'));
const DispatchedTripsListPage = lazy(() => import('src/pages/dashboard/dispatched-trips/list'));

// Invoice
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));

// Invoice
const DriverPaymentListPage = lazy(() => import('src/pages/dashboard/driver-payment/list'));
const DriverPaymentEditPage = lazy(() => import('src/pages/dashboard/driver-payment/edit'));

//// management

// User
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));

// Group
const GroupListPage = lazy(() => import('src/pages/dashboard/group/list'));
const GroupEditPage = lazy(() => import('src/pages/dashboard/group/edit'));

// Customer
const CustomerListPage = lazy(() => import('src/pages/dashboard/customer/list'));
const CustomerEditPage = lazy(() => import('src/pages/dashboard/customer/edit'));

// Location
const LocationListPage = lazy(() => import('src/pages/dashboard/location/list'));
const LocationEditPage = lazy(() => import('src/pages/dashboard/location/edit'));

// Report
const ReportListPage = lazy(() => import('src/pages/dashboard/report/list'));
const ReportEditPage = lazy(() => import('src/pages/dashboard/report/edit'));
const ReportViewPage = lazy(() => import('src/pages/dashboard/report/view'));

// Template
const TemplateListPage = lazy(() => import('src/pages/dashboard/template/list'));
const TemplateEditPage = lazy(() => import('src/pages/dashboard/template/edit'));

// Truck
const TruckListPage = lazy(() => import('src/pages/dashboard/truck/list'));
const TruckEditPage = lazy(() => import('src/pages/dashboard/truck/edit'));

// Private Chassis
const PrivateChassisListPage = lazy(() => import('src/pages/dashboard/private-chassis/list'));
const PrivateChassisEditPage = lazy(() => import('src/pages/dashboard/private-chassis/edit'));

// Trailer Type
const TrailerTypeListPage = lazy(() => import('src/pages/dashboard/trailer-type/list'));
const TrailerTypeEditPage = lazy(() => import('src/pages/dashboard/trailer-type/edit'));

// Terminal
const TerminalListPage = lazy(() => import('src/pages/dashboard/terminal/list'));
const TerminalEditPage = lazy(() => import('src/pages/dashboard/terminal/edit'));

// Steamship
const SteamshipListPage = lazy(() => import('src/pages/dashboard/steamship/list'));
const SteamshipEditPage = lazy(() => import('src/pages/dashboard/steamship/edit'));

// Import Page
const ImportDataPage = lazy(() => import('src/pages/dashboard/data-import'));

const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const PlannerPage = lazy(() => import('src/pages/dashboard/planner'));

// ----------------------------------------------------------------------

function SuspenseOutlet() {
  const pathname = usePathname();
  return (
    <Suspense key={pathname} fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  );
}

const dashboardLayout = () => (
  <DashboardLayout>
    <SuspenseOutlet />
  </DashboardLayout>
);

export const dashboardRoutes: RouteObject[] = [
  {
    path: 'dashboard',
    element: CONFIG.auth.skip ? dashboardLayout() : <AuthGuard>{dashboardLayout()}</AuthGuard>,
    children: [
      // overview
      { index: true, element: <CSDashboardPage /> },
      {
        path: 'shipments',
        children: [
          { element: <ShipmentsListPage />, index: true },
          { path: 'new', element: <ShipmentsCreatePage /> },
          { path: ':id', element: <ShipmentsEditPage /> },
        ],
      },
      { path: 'billed-shipments', element: <BilledShipmentListPage /> },
      { path: 'dispatched-trips', element: <DispatchedTripsListPage /> },
      { path: 'undispatched-trips', element: <UndispatchedTripsListPage /> },
      {
        path: 'invoices',
        children: [
          { index: true, element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceEditPage /> },
        ],
      },
      {
        path: 'driver-payments',
        children: [
          { index: true, element: <DriverPaymentListPage /> },
          { path: ':id', element: <DriverPaymentEditPage /> },
        ],
      },
      {
        path: 'reports-view',
        children: [
          { path: ':id', element: <ReportViewPage /> },
        ],
      },

      // management
      {
        path: 'users',
        children: [
          { index: true, element: <UserListPage /> },
          { path: ':id', element: <UserEditPage /> },
        ],
      },
      {
        path: 'groups',
        children: [
          { index: true, element: <GroupListPage /> },
          { path: ':id', element: <GroupEditPage /> },
        ],
      },
      {
        path: 'customers',
        children: [
          { index: true, element: <CustomerListPage /> },
          { path: ':id', element: <CustomerEditPage /> },
        ],
      },
      {
        path: 'locations',
        children: [
          { index: true, element: <LocationListPage /> },
          { path: ':id', element: <LocationEditPage /> },
        ],
      },
      {
        path: 'reports',
        children: [
          { index: true, element: <ReportListPage /> },
          { path: ':id', element: <ReportEditPage /> },
        ],
      },
      {
        path: 'templates',
        children: [
          { index: true, element: <TemplateListPage /> },
          { path: ':id', element: <TemplateEditPage /> },
        ],
      },
      {
        path: 'trucks',
        children: [
          { index: true, element: <TruckListPage /> },
          { path: ':id', element: <TruckEditPage /> },
        ],
      },
      {
        path: 'private-chassis',
        children: [
          { index: true, element: <PrivateChassisListPage /> },
          { path: ':id', element: <PrivateChassisEditPage /> },
        ],
      },
      {
        path: 'trailer-type',
        children: [
          { index: true, element: <TrailerTypeListPage /> },
          { path: ':id', element: <TrailerTypeEditPage /> },
        ],
      },
      {
        path: 'terminals',
        children: [
          { index: true, element: <TerminalListPage /> },
          { path: ':id', element: <TerminalEditPage /> },
        ],
      },
      {
        path: 'steamships',
        children: [
          { index: true, element: <SteamshipListPage /> },
          { path: ':id', element: <SteamshipEditPage /> },
        ],
      },

      // tools
      {
        path: 'imports',
        children: [{ element: <ImportDataPage />, index: true }],
      },
      {
        path: 'chat',
        element: <ChatPage />,
      },
      {
        path: 'planner',
        element: <PlannerPage />,
      },
    ],
  },
];
