import type { Terminal } from 'src/types';

import useSWR from 'swr';
import { useMemo } from 'react';

import { CONFIG } from 'src/global-config';
import axios, { fetcher, endpoints } from 'src/lib/axios';

const URL = endpoints.terminals;

export type TerminalFormData = Omit<Partial<Terminal>, 'logo'> & { logo?: File | string | null };

export function useListTerminals(filter?: any, sort?: any, pagination?: any, relations?: any) {
  const request: any = [
    URL,
    {
      params: {
        filter,
        sort,
        pagination,
        relations,
      },
    },
  ];
  const { data, isLoading, error, isValidating, mutate } = useSWR(request, fetcher, CONFIG.swr);

  const memoizedValue = useMemo(
    () => ({
      terminals: (data || []) as Terminal[],
      terminalsLoading: isLoading,
      terminalsError: error,
      terminalsValidating: isValidating,
      terminalsEmpty: !isLoading && !data?.length,
      mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetTerminal(id: any, relations?: any) {
  const request = id && [`${URL}/${id}`, { params: { relations } }];
  const { data, isLoading, error, isValidating } = useSWR(request, fetcher);

  const memoizedValue = useMemo(
    () => ({
      terminal: data as Terminal,
      terminalLoading: isLoading,
      terminalError: error,
      terminalValidating: isValidating,
      terminalEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export async function createTerminal(terminal: TerminalFormData) {
  const { logo } = terminal;
  if (!terminal.id && logo instanceof File) {
    delete terminal.logo;
  }
  const response = await axios.post<Terminal>(URL, terminal);
  if (logo instanceof File) {
    const { url } = await uploadTerminalLogo(response.data.id, logo);
    return axios.put<Terminal>(`${URL}/${response.data.id}`, { logo: url });
  }
  return response;
}

export async function updateTerminal(terminal: TerminalFormData) {
  if (!terminal.id) {
    throw new Error('Terminal ID is required');
  }
  const { logo } = terminal;
  if (logo instanceof File) {
    const { url } = await uploadTerminalLogo(terminal.id, logo);
    terminal.logo = url;
  }
  return axios.put<Terminal>(`${URL}/${terminal.id}`, terminal);
}

export function saveTerminal(terminal: TerminalFormData) {
  return terminal.id ? updateTerminal(terminal) : createTerminal(terminal);
}

export function deleteTerminal(id: number) {
  return axios.delete(`${URL}/${id}`);
}

export function uploadTerminalLogo(id: number, logo: File) {
  const formData = new FormData();
  formData.append('logo', logo);
  return axios
    .post<{ url: string }>(`${URL}/${id}/logo`, formData)
    .then((response) => response.data);
}

export async function listTerminalsForLogin() {
  try {
    const response = await axios.get(`${URL}/for-login`, {
      headers: {
        Accept: 'application/json',
      },
    });
    const { data } = response;
    return data as any;
  } catch (error) {
    console.error('Failed to fetch terminals', error);
    return null;
  }
}
